import React, {useState} from "react";
import Image from "next/image";
import { useCustomTranslation } from '@utils/translations'; 
import importantNotice from "./../../public/important-notice.svg";
import axios from "axios";
import { urlGenerateToken } from "../utils/server-links";
import CountryDropdown from '../CountryDropdown/CountryDropdown';
import ButtonLoader from '../Buttons/ButtonLoader';
import HTMLRender from "@components/utils/htmlrender";

type ModalRCProps = {
    title: string;
    show: boolean;
    onCallback: any;
    data: { name: string, code: string, phonePrefix: string };
};


const ModalRC = (props: ModalRCProps) => {
    let { title = "", show, } = props;
    const { data, onCallback } = props;
    const [isContinueDisabled, setIsContinueDisabled] = useState(false);
    const [country, setCountry] = useState({ name: '', code: '', phonePrefix: ''});
    const { t } = useCustomTranslation();
    const [countryChanged, setCountryChanged] = useState(false)

    const onChange = (data: any, name: string) => {
        if (name === 'country') {
            // if (data.isForbidden) {
            //     setIsContinueDisabled(true);
            //     return;
            // }
            setIsContinueDisabled(false);
            setCountry(data);
        }
    }
    const onCountryChanged = () => {
        setCountryChanged(true)
    }

    const submitData = () => {
        onCallback(country);
    }


    return (
        <>
            {show && (
                <>
                    <div className="gray-overlay backdrop-blur-md"></div>
                    <div className="fixed  z-50  popup-modal bg-white md:w-[800px] w-[365px] max-w-[calc(100%-20px)] overflow-auto h-auto max-h-[80%]">
                        <div className="line-background md:px-[80px] px-[20px]  md:pb-[150px] pb-[50px] md:pt-[50px] pt-[20px] text-center max-w-[680px] mx-auto">
                           <div className="max-w-[35%] mx-auto md:max-w-full">
                                <Image
                                className="m-auto mb-2"
                                src={importantNotice}
                                width="140"
                                height="140"
                                alt="checkmark"
                                />
                            </div>
                            <h1 className='text-3xl md:text-4xl text-dark-gray mb-4 md:mb-6 font-["Gilroy"] font-bold'>
                                {title}
                            </h1>

                            <div className="full-dropdown md:mb-9 mb-7 max-w-[384px] w-full m-auto my-[24px]">
                                <CountryDropdown placeholder={t('residence.forbiddenCountry.search_placeholder')} activatedCountries forceWhiteBgInputLabel name='country' defaultCountry={data.code} onChange={onChange} onCountryChanged={onCountryChanged} />
                            </div>

                            <ButtonLoader
                                classes={`pl-[62px] pr-[62px] md:mt-11 mt-4 text-white ${countryChanged ? `` : `opacity-0`}`}
                                isLoading={false}
                                isDisabled={false}
                                buttonText={t("residence.continue")}
                                buttonType="submit"
                                onClicked={submitData} />
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default React.memo(ModalRC);
