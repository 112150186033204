import { Combobox } from '@headlessui/react';
import React, { useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import useStore from '../store';
import { CheckIcon } from '@heroicons/react/20/solid';
import styles from './styles.module.css';
import { Country } from '../interfaces/types';
import useComponentVisible from '../hooks/useComponentVisible';
import { useCustomTranslation } from '@utils/translations';
import ReactCountryFlag from "react-country-flag"
import { useRouter } from 'next/router';

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

type CountryDropdownProps = {
    defaultCountry: string | null;
    onChange: any;
    onCountryChanged?: () => void;
    name: string;
    label?: string;
    forceWhiteBgInputLabel?: boolean;
    subQuestion?: boolean;
    activatedCountries?: boolean;
    placeholder?: string;
    id?: string;
}

const CountryDropdown = (props: CountryDropdownProps) => {
    const router = useRouter();
    const { defaultCountry, onChange, name, label, forceWhiteBgInputLabel, subQuestion, activatedCountries, placeholder, onCountryChanged, id } = props;
    const [country, setCountry] = useState({ name: '', code: '', phonePrefix: '' });
    const [query, setQuery] = useState('');
    const countries = useStore(state => state.countries) as Country[];
    const searchRef = useRef<HTMLButtonElement>(null);
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const { t } = useCustomTranslation();
    const [countryChanged, setCountryChanged] = useState(false);

    let filteredCountries =
        query === '' ? countries.sort((a, b) => { return a.name.localeCompare(b.name) }) : countries.sort((a, b) => { return a.name.localeCompare(b.name) }).filter((country: { name: string; }) => {
            return country.name.toLowerCase().includes(query.toLowerCase())
        });

    if (activatedCountries) {
        filteredCountries = filteredCountries.filter(item => item.activated_country === true);
    }

    useEffect(() => {
        if (router.isReady){
            if (defaultCountry && defaultCountry !== '') {
                const defaultCountryObject = countries.filter((country) => country.code === defaultCountry);
                if (activatedCountries) {
                    setCountry(defaultCountryObject[0]?.activated_country ? defaultCountryObject[0] : filteredCountries[0]);
                    onChange(defaultCountryObject[0]?.activated_country ? defaultCountryObject[0] : filteredCountries[0], name);
                } else {
                    setCountry(defaultCountryObject[0]);
                    onChange(defaultCountryObject[0], name);
                }
            } else {
                setCountry(countries[0]);
                onChange(countries[0], name);
            }
        }
    }, [countries, defaultCountry, router.isReady]);

    const updateValue = (value: { name: string; code: string; phonePrefix: string; }) => {
        setCountry(value);
        onChange(value, name);
        setIsComponentVisible(false);
        setCountryChanged(true)
        if (onCountryChanged) {
            onCountryChanged();
          }
    }

    const openAndFocusSearch = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setQuery('');
        setIsComponentVisible(true);
        searchRef.current?.click();
    }

    useEffect(() => {
        searchRef.current?.click();
    }, [isComponentVisible]);

    if (!country) {
        return (<></>);
    }

    return (
        <div className={`relative`} ref={ref}>
            <div className="relative text-left">
                <button id={id} onClick={(e) => openAndFocusSearch(e)}
                    className={`flex peer w-full cursor-pointer items-center rounded-md text-sm sm:text-sm text-gray border-[1px] border-solid border-[#D3D5D6] h-13 ltr:pl-[17px] rtl:pr-[17px] ltr:pr-8 ltr:md:pr-10 rtl:pl-8 rtl:md:pl-10 py-4 pr-[50px] px-[18px] z-[2] ${subQuestion ? 'bg-[#FAFAFA]' : 'bg-transparent md:bg-white'} focus:outline-none focus:ring-transparent`}>
                    <label
                        htmlFor={name}
                        className={`transform text-gray ${forceWhiteBgInputLabel ? 'bg-white' : !forceWhiteBgInputLabel && subQuestion ? 'bg-linearGradientInputLightGray' : 'bg-white md:bg-linearGradientInput'} px-1 transition-all absolute flex items-center top-0  text-xs 2xl:h-[26px] left-[12px] z-[3] -translate-y-1/2`}
                    >
                        {label ? t(label) : t(`residence.${name}`)}
                    </label>
                    {placeholder && !countryChanged ?
                     <span className={`flex items-center px-[10px] md:pl-[20px] whitespace-nowrap max-w-[calc(100%-24px)] overflow-hidden text-[#111114] font-[600]`}>
                        {placeholder}
                    </span>
                    :
                    <>
                    <span className={`flex items-center flex-[0_0_24px] rounded overflow-hidden`}>
                        {country.name && (
                            <ReactCountryFlag
                                countryCode={country?.code}
                                svg
                                style={{
                                    width: '24px',
                                    height: '18px',
                                }}
                                title={country?.code}
                            />
                        )}
                    </span>
                    <span className={`flex items-center px-[10px] md:pl-[20px] whitespace-nowrap max-w-[calc(100%-24px)] overflow-hidden text-[#111114] font-[600]`}>
                        {country?.name}
                    </span>
                    </>
                    }
                    <span className={`ltr:ml-3 rtl:mr-3 absolute inset-y-0 ltr:right-0 rtl:left-0 flex items-center ltr:pr-4 rtl:pl-4 cursor-pointer focus:outline-none`}>
                        <img width={14} height={14} src="/images/arrow_down.svg" alt="dropdown arrow" aria-hidden="true" />
                    </span>
                </button>
            </div>
            {isComponentVisible && (
                <Combobox as="div" value={country || ''} onChange={(val: { name: string; code: string; phonePrefix: string; }) => updateValue(val)}>
                    <div className={`${styles.countryDropDown} bg-white z-20 absolute w-full`}>
                        <div className={`${subQuestion ? 'bg-[#FAFAFA]' : 'bg-white'} px-[14px] py-[12px] shadow-lg`}>
                            <span className={`ltr:ml-3 rtl:mr-3 absolute inset-y-0 ltr:left-4 rtl:right-4 flex items-center ltr:pr-4 rtl:pl-4 cursor-pointer focus:outline-none`}>
                                <img width={18} height={18} src="/images/search-normal.svg" alt="dropdown arrow" aria-hidden="true" color="#999999" />
                            </span>
                            <Combobox.Input
                                name={name}
                                className={`flex peer w-full cursor-pointer items-center rounded text-sm sm:text-sm text-gray border-[1px] border-solid border-gray h-10 pl-[45px] py-2 pr-[50px] z-[2] ${subQuestion ? 'bg-[#FAFAFA]' : 'bg-white'} focus:outline-none focus:ring-transparent focus:border-current`}
                                onChange={(event) => setQuery(event.target.value)}
                                autoFocus
                            // displayValue={(country: { name: string; code: string; phonePrefix: string; }) => country?.name}

                            />
                            <Combobox.Button className="ltr:ml-3 rtl:mr-3 absolute inset-y-0 ltr:right-0 rtl:left-0 flex items-center ltr:pr-4 rtl:pl-4 cursor-pointer focus:outline-none invisible" ref={searchRef}>
                                <ReactCountryFlag
                                    countryCode={country?.code}
                                    svg
                                    style={{
                                        width: '24px',
                                        height: '18px',
                                    }}
                                    title={country?.code}
                                />
                            </Combobox.Button>
                        </div>

                        {filteredCountries.length > 0 && (
                            <Combobox.Options static className={`absolute z-10 max-h-[180px] w-full overflow-hidden overflow-y-auto rounded-b-md ${subQuestion ? 'bg-[#FAFAFA]' : 'bg-white'} py-1 text-base shadow-lg focus:outline-none sm:text-sm text-left px-3`}>
                                {filteredCountries.map((country) => (
                                    <Combobox.Option
                                        key={country.code}
                                        value={country}
                                        className={({ active, selected }) =>
                                            classNames(
                                                'relative cursor-pointer select-none py-2 ltr:pl-3 rtl:pr-3 rounded-md mb-[5px] flex',
                                                active ? `text-black` : 'text-gray-900',
                                                selected ? `${styles.selected}` : ''
                                            )
                                        }
                                    >
                                        {({ active, selected }) => (
                                            <>
                                                <span className={`flex items-center ltr:pr-3 rtl:pl-3`}>
                                                    <ReactCountryFlag
                                                        countryCode={country?.code}
                                                        svg
                                                        style={{
                                                            width: '24px',
                                                            height: '18px',
                                                        }}
                                                        title={country?.code}
                                                    />
                                                </span>
                                                <span className={classNames('flex items-center truncate', selected ? `font-semibold` : '')}>{country.name}</span>
                                                {selected && (
                                                    <span
                                                        className={classNames(
                                                            'absolute inset-y-0 ltr:right-0 rtl:left-0 flex items-center ltr:pr-4 rtl:pl-4',
                                                            active ? 'text-black' : 'text-black'
                                                        )}
                                                    >
                                                        <CheckIcon width={15} height={15} aria-hidden="true" color="#13A84F" />
                                                    </span>
                                                )}
                                            </>
                                        )}
                                    </Combobox.Option>
                                ))}
                            </Combobox.Options>
                        )}
                    </div>
                </Combobox>
            )}
        </div>
    );
}

export default CountryDropdown;
